import React, { useEffect, useState } from "react"

import SEO from "../components/seo"
import Layout from "../components/Layout"
import Slider from "../components/Slider"
import SliderMobile from "../components/Slider_mobile"

import {IsMobileDevice} from '../utils'
import AgeGate2 from "../components/agegate"

const Home = () => {

  const [isMobile, setIsMobile] = useState(false)
  const [mobileLight, setMobileLight] = useState(false)
  const [age, setAge] = useState(true);

  useEffect(()=>{
    let local = sessionStorage.getItem('age')
    if (local) setAge(false)
    setIsMobile(IsMobileDevice())
    setMobileLight(IsMobileDevice())
  },[])

  return (
    !age ? (
      <Layout mobileLight={mobileLight} isMobile={isMobile}>
        <SEO title="Home"/>
          {isMobile ? <SliderMobile/> : <Slider/> }
      </Layout>
    ):(
      <AgeGate2 path="/"/>
    )
  )

}

export default Home

